body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.mbx {
  margin-bottom: 1.5rem !important;
}
/**Table**/
table {
  width: 100%;
  background-color: #fff;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column100 {
  width: 130px;
  padding-left: 25px;
}

.column100.column1 {
  width: 20%;
  /*padding-left: 42px;*/
}

.column100.column2 {
  width: 65%;
  /*padding-left: 42px;*/
}
.column100.column3 {
  width: 20%;
  /*padding-left: 42px;*/
}

.row100.head th {
  padding-top: 24px;
  padding-bottom: 20px;
}

.row100 td {
  padding-top: 18px;
  padding-bottom: 14px;
}

.table100.ver4 td {
  font-size: 14px;
  color: #000;
  line-height: 1.4;
}

.table100.ver4 th {
  font-size: 12px;
  color: #fff;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: #f57f01;
}

.th-bg-orange {
  background-color: #f57f01;
}

.table100.ver4 .hov-column-head-ver4 {
  background-color: #f57f01 !important;
}

.btn-download,
.btn-download:hover,
.btn-download:link {
  background-color: #009e62 !important;
  color: #fff !important;
  font-weight: 300;
  padding: 10px;
  border-radius: 8px;
  @media all and (max-width: 500px) {
    padding: 10px 15px;
  }
}

button[disabled]:active,
button[disabled],
button[disabled]:hover {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.m-b-110 {
  margin: 2rem 0;
}

._text-truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logout-btn {
  cursor: pointer;
}

.search-list-center {
  text-align: center;
  padding: 0.5rem;
}
.search-cusor {
  cursor: pointer;
}
.text-centering {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 260px;
}

.full-width-datagreffe {
  width: 100%;
}
.btn-login-datagreffe {
  background: #f57f01 !important;
  color: #fff !important;
  padding: 10px 12px;
}

.label-login {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #0d1136;
  line-height: 25px;
}

.login-input {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #a9a9b2;
  font-size: 16px;
  font-weight: 400;
  color: #0d1136;
  line-height: 19px;
  padding: 0 18px;
  box-sizing: border-box;
  transition: border-color 0.25s ease;
}
.login-input:hover,
.login-input:focus {
  outline: 0;
}
.login-input ::placeholder {
  font-size: 18px;
}
.login-input:focus {
  border-color: #069;
}
.login-input::placeholder {
  color: #77798c;
  font-size: 14px;
}
.login-input::-webkit-inner-spin-button,
.login-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-input.disabled .inner-wrap {
  cursor: not-allowed;
  opacity: 0.6;
}

.h-1{
  height: 0.25rem;
}

.h-2{
  height: 0.5rem;
}

.h-3{
  height: 0.75rem;
}

.h-4{
  height: 1rem;
}

.h-5{
  height: 1.25rem;
}

.h-6{
  height: 1.5rem;
}

.h-8{
  height: 2rem;
}

.h-10{
  height: 2.5rem;
}

.h-12{
  height: 3rem;
}

.h-16{
  height: 4rem;
}

.h-20{
  height: 5rem;
}

.h-24{
  height: 6rem;
}

.h-32{
  height: 8rem;
}

.h-40{
  height: 10rem;
}

.h-48{
  height: 12rem;
}

.h-56{
  height: 14rem;
}

.h-64{
  height: 16rem;
}

/*
  Stripe
 */

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.index-o {
  z-index: 0 !important;
}



.floating-button {
  position: fixed;
  bottom: 34%;
  right: 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 100;
  cursor: pointer;
}

.documents-floating-button-module {
  background-color: #009e62;
  border-radius: 10px;
  bottom: 50px;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  height: 50px;
  padding: 10px 18px;
  position: fixed;
  right: 100px;
  z-index: 10000;
  text-decoration: none;
  border: none;
}
.documents-floating-button-module
  .documents-floating-button-module-flexWrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.documents-floating-button-module-icon {
  color: #fff;
  width: 20px;
  box-sizing: border-box;
}
.documents-floating-button-module-text {
  color: #fff;
  font-size: 16px;
}
.documents-floating-button-module-price {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #009e62;
  display: flex;
  font-size: 16px;
  height: 25px;
  justify-content: center;
  padding: 0 7px;
}

.search-pagination-wrapper {
  margin: 10px auto;
  width: 25%;
}
.search-pagination-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-pagination-item {
  margin: 10px;
  width: 100%;
}
.search-pagination-number {
  font-size: 1.2rem;
  font-weight: bold;
}
.search-pagination-link {
  width: 100%;
}

.search-detail-container {
  background-color: #fff;
  /* border: 1px solid #f1f1f1; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  padding: 15px 1rem;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
}

.search-detail-container-1 {
  background-color: #fff;
  /* border: 1px solid #f1f1f1; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  /* padding: 10px; */
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
}
.search-detail-header {
  margin: 1rem 5px 0 5px;
}

.search-detail-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 18px;
  font-weight: 700;
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-detail-box-date {
  margin: 0.5rem 0;
}

.search-detail-date {
  font-size: 16px;
  font-weight: bold;
}
.search-detail-date-statut {
  color: #f57f01;
  margin-left: 10px;
}
.search-detail-flex-item {
  margin: 10px 0;
}
.search-detail-flex-item-label,
.search-detail-flex-item-value,
.search-detail-flex-item-value-colored {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.search-detail-flex-item-label {
  color: #000;
  text-align: left;
}

.search-detail-flex-item-value-colored {
  background: pink;
  padding: 7px 15px;
  border-radius: 10px;
}

.search-detail-text {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
}
.search-detail-chef-box {
  margin: 1.5rem 0;
}
.search-detail-chef {
  color: #009e62;
  font-size: 20px;
  font-weight: bold;
}

.search-detail-table {
  th,
  td {
    padding: 13px 0;
  }
}

.input[type=checkbox]:not(.input--switch) {
  width: 16px;
  height: 16px;
  border-radius: 0.2em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.input[type=checkbox]:not(.input--switch):before {
  content: "";
  transition: all 0.2s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 80%;
  width: 100%;
  height: 100%;
  position: absolute;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
}

.input[type=checkbox]:not(.input--switch):checked {
  --bg-opacity: 1;
  background-color: #F57F01;
  background-color: rgba(245, 127, 1, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #F57F01;
  border-color: rgba(28, 63, 170, var(--border-opacity));
}

.input[type=checkbox]:not(.input--switch):checked:before {
  opacity: 1;
}

.input.input--switch[type=checkbox] {
  width: 38px;
  height: 24px;
  padding: 1px;
  outline: 0;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;
}

.input.input--switch[type=checkbox]:before {
  content: "";
  width: 22px;
  height: 22px;
  transition: all 0.2s ease-in-out;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 9999px;
}
  
.input.input--switch[type=checkbox]:checked {
  --bg-opacity: 1;
  background-color: #F57F01;
  background-color: rgba(245, 127, 1, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #F57F01;
  border-color: rgba(28, 63, 170, var(--border-opacity));
}

.input.input--switch[type=checkbox]:checked::before {
  margin-left: 13px;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}